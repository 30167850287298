<template>
  <div class="box">
    <el-container>
      <el-main>
        <el-row>
          <div v-if="showIndex == 0">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <div style="font-size: 15px; font-weight: 300; margin-top: 15px">
                {{ nowDate }} {{ time }} —— {{ nowDate }} {{ time1 }}
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <div class="itemBox">
                <div>
                  总入款金额：{{ info.jzInfo ? info.jzInfo.inTotal : 0 }}
                </div>
                <div>汇率：{{ info.jzInfo ? info.jzInfo.uExRate : 0 }}</div>
                <div>费率：{{ info.jzInfo ? info.jzInfo.rate : 0 }}</div>
                <div>
                  应下发：{{ info.jzInfo ? info.jzInfo.inTotal : 0 }}|{{
                    info.jzInfo ? info.jzInfo.yingUsdt : 0
                  }}(usdt)
                </div>
                <div>
                  已下发：{{ info.jzInfo ? info.jzInfo.yixiafaRmb : 0 }}|{{
                    info.jzInfo ? info.jzInfo.outTotalU : 0
                  }}(usdt)
                </div>
                <div>
                  未下发：{{ info.jzInfo ? info.jzInfo.weiRmb : 0 }}|{{
                    info.jzInfo ? info.jzInfo.weiUsdt : 0
                  }}(usdt)
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <div class="itemBox">
                <div class="title">入款 ({{ info.jzLogsRu.length }}笔)</div>
                <el-table
                  :data="info.jzLogsRu"
                  border
                  style="width: 100%"
                  size="small"
                >
                  <el-table-column
                    prop="createTime"
                    label="时间"
                    align="center"
                    min-width="150px"
                  >
                    <template slot-scope="scope">{{
                      scope.row.createTime | dateFormat
                    }}</template>
                  </el-table-column>
                  <el-table-column
                    prop="quantity"
                    label="金额"
                    align="center"
                    min-width="150px"
                  >
                  </el-table-column>
                  <el-table-column
                    label="操作人"
                    align="center"
                    min-width="300px"
                  >
                    <template slot-scope="scope">{{
                      scope.row.user.nickName
                    }}</template>
                  </el-table-column>
                </el-table>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <div class="itemBox">
                <div class="title">下发 ({{ info.jzLogsChu.length }}笔)</div>
                <el-table
                  :data="info.jzLogsChu"
                  border
                  style="width: 100%"
                  size="small"
                >
                  <el-table-column
                    prop="createTime"
                    label="时间"
                    align="center"
                    min-width="150px"
                  >
                    <template slot-scope="scope">{{
                      scope.row.createTime | dateFormat
                    }}</template>
                  </el-table-column>
                  <el-table-column
                    prop="quantity"
                    label="金额"
                    align="center"
                    min-width="150px"
                  >
                  </el-table-column>
                  <el-table-column label="操作人" align="center" width="300px">
                    <template slot-scope="scope">{{
                      scope.row.user.nickName
                    }}</template>
                  </el-table-column>
                </el-table>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <div class="itemBox1">
                <div class="title">入款操作人分类</div>
                <el-table
                  :data="info.ruUserGroupList"
                  border
                  size="small"
                  style="width: 100%"
                >
                  <el-table-column
                    prop="userName"
                    label="操作人"
                    align="center"
                    min-width="250px"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="amountSum"
                    label="金额"
                    align="center"
                    min-width="150px"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="quantitySum"
                    label="换算"
                    align="center"
                  >
                  </el-table-column>
                </el-table>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <div class="itemBox1">
                <div class="title">入款汇率分类</div>
                <el-table
                  :data="info.ruExRruateGroupList"
                  border
                  style="width: 100%"
                  size="small"
                >
                  <el-table-column prop="exRate" label="汇率" align="center">
                  </el-table-column>
                  <el-table-column
                    prop="amountSum"
                    label="金额"
                    align="center"
                    min-width="150px"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="quantitySum"
                    label="换算"
                    align="center"
                  >
                  </el-table-column>
                </el-table>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <div style="text-align: center; margin-top: 20px">
                <div>
                  <el-button size="small" @click="export1">下载excel</el-button>
                </div>
                <div
                  style="display: flex; justify-content: center; margin: 10px 0"
                >
                  <el-button size="small" @click="upper">上一天</el-button
                  ><el-button size="small" @click="next">下一天</el-button>
                </div>
                <div style="margin-bottom: 10px">
                  <el-button size="small" @click="selectMore"
                    >查看最近30天汇总</el-button
                  >
                </div>
                <el-date-picker
                  v-model="nowDate"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                  @change="selectTime"
                >
                </el-date-picker>
              </div>
            </el-col>
          </div>
          <div v-if="showIndex == 1">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <div style="margin-top: 20px">
                <el-button icon="el-icon-arrow-left" @click="back"
                  >返回</el-button
                >
              </div>
              <div class="itemBox" style="font-size: 14px">
                <div style="margin-bottom: 20px">
                  最近30天总入款：{{ allInfo.totalInQuantity }}
                </div>
                <div v-for="item in allInfo.days" :key="item">
                  <div>{{ item.day }}</div>
                  <div>
                    入款金额：{{ item.inQuantity }}({{ item.inCount }}笔)
                  </div>
                  <div>
                    下发金额：{{ item.outQuantity }}({{ item.outCount }}笔)
                  </div>
                </div>
              </div>
            </el-col>
          </div>
          <!-- <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <div style="text-align: center; margin-top: 20px">
              <a
                href="https://www.yu444.com/"
                target="blank"
                style="text-decoration: none; color: #3c8dbc"
                >汇旺担保官方网站:www.yu444.com</a
              >
            </div>
          </el-col> -->
        </el-row></el-main
      >
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: {},
      allInfo: {},
      nowDate: "",
      time: "00:00:00",
      time1: "24:00:00",
      chat_id: "",
      timeList: [],
      showIndex: 0,
    };
  },
  created() {
    let webApp = window.Telegram.WebApp;
    let initDataUnsafe = webApp.initDataUnsafe;
    let chat_id = initDataUnsafe.start_param;
    this.chat_id = chat_id;
    let date = new Date();
    let year = date.getFullYear();
    var month =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    this.nowDate = `${year}-${month}-${day}`;
    this.getList();
  },
  methods: {
    getList() {
      let param = {
        // groupId: -4214830659,
        // day: "2024-05-30",
        groupId: this.chat_id,
        day: this.nowDate,
      };
      this.$axios
        .post(`/bot/jzInfo/tgGetInfo`, param)
        .then(({ data }) => {
          this.info = data;
          this.timeList = data.jzInfosRu;
        })
        .catch((error) => {
          let msg = error.data && error.data.msg;
          this.$message.error(msg);
        })
        .finally(() => {});
    },
    export1() {
      this.$axios
        .get(`/bot/jzInfo/export?groupId=${this.chat_id}&day=${this.nowDate}`)
        .then(({ data }) => {
          const fileName = data;
          const link = document.createElement("a");
          link.style.display = "none";
          link.href =
            "https://jz.kisleo.com/bot/jzInfo/export?groupId=" +
            this.chat_id +
            "&day=" +
            this.nowDate;
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(link.href); // 释放URL 对象
          document.body.removeChild(link);
        })
        .catch((error) => {
          let msg = error.data && error.data.msg;
          this.$message.error(msg);
        })
        .finally(() => {});
    },
    next() {
      if (this.nowDate == this.timeList[this.timeList.length - 1].day) {
        return;
      } else {
        let index = "";
        for (var i = 0; i < this.timeList.length; i++) {
          if (this.nowDate == this.timeList[i].day) {
            index = i;
          }
        }
        this.nowDate = this.timeList[index + 1].day;
        this.getList();
      }
    },
    upper() {
      if (this.nowDate == this.timeList[0].day) {
        return;
      } else {
        let index = "";
        for (var i = 0; i < this.timeList.length; i++) {
          if (this.nowDate == this.timeList[i].day) {
            index = i;
          }
        }
        this.nowDate = this.timeList[index - 1].day;
        this.getList();
      }
    },
    selectTime(val) {
      this.nowDate = val;
      this.getList();
    },
    selectMore() {
      this.$axios
        .get(`/bot/jzInfo/count/-4214830659`)
        .then(({ data }) => {
          this.allInfo = data;
        })
        .catch((error) => {
          let msg = error.data && error.data.msg;
          this.$message.error(msg);
        })
        .finally(() => {});
      this.showIndex = 1;
    },
    back() {
      this.showIndex = 0;
    },
  },
};
</script>
<style>
body {
  background: #ecf0f5;
  padding: 0 0;
  margin: 0 0 !important;
}
.box {
  padding: 0 10px;
  box-sizing: border-box;
}
.itemBox {
  border-top: #3c8dbc 3px solid;
  border-radius: 3px;
  overflow: hidden;
  margin-top: 20px;
  padding: 10px 10px;
  background: #fff;
  font-size: 14px;
}
.itemBox1 {
  border-top: #3c8dbc 3px solid;
  border-radius: 3px;
  overflow: hidden;
  margin-top: 20px;
  padding: 10px 10px;
  background: #fff;
  font-size: 14px;
}
.title {
  font-size: 18px;
  margin-bottom: 20px;
}
.bigBox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
</style>
